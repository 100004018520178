<template>
  <component is="b-card">
    <b-row>
      <b-col>
        <b-form-group :label="$t('Select client')" label-for="client">
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="clients"
            v-model="selectedClient"
            label="name"
            input-id="client"
            @search="fetchClientOptions"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group :label="$t('Global Ref')" label-for="name">
          <b-form-input id="name" v-model="globalRef" />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group :label="$t('Select recoverer')" label-for="client">
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="recoverers"
            v-model="selectedRecoverer"
            label="name"
            input-id="client"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group :label="$t('Date')" label-for="client">
          <flat-pickr
            :config="{
              mode: 'range',
              dateFormat: 'Y-m-d',
            }"
            v-model="selectedDates"
            class="form-control"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-button
          :disabled="
            !selectedClient ||
            selectedDates.split('to').length != 2 ||
            !globalRef
          "
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="searchOperations(false)"
        >
          {{ $t("Search") }}
        </b-button>
      </b-col>
    </b-row>

    <b-card no-body class="mb-2 mt-2">
      <b-table
        ref="refTable"
        class="position-relative"
        responsive
        :fields="['bl_ref', 'operation_date', 'amount', 'reste', 'actions']"
        show-empty
        :empty-text="$t('No matching records found')"
        :items="selectedOperations"
      >
        <template #cell(amount)="data"> {{ data.item.amount }} DH </template>
        <template #cell(reste)="data"> {{ data.item.reste }} DH </template>
        <template #cell(actions)="data">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            class="btn-icon"
            @click="deleteOperation(data.item)"
          >
            <feather-icon icon="TrashIcon" size="16" />
          </b-button>
        </template>
      </b-table>

      <b-row class="mt-3">
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            block
            variant="primary"
            :disabled="selectedOperations.length === 0 || !selectedRecoverer"
            @click="saveSitutation"
          >
            {{ $t("Save") }}
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </component>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BForm,
  BFormGroup,
  BInputGroup,
  BModal,
  VBModal,
} from "bootstrap-vue";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import instance from "@/libs/axios";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BModal,
    VBModal,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BForm,
    BFormGroup,
    BInputGroup,

    vSelect,
    flatPickr,
  },

  directives: {
    Ripple,
  },
  data() {
    return {
      clients: [],
      globalRef: null,
      selectedClient: null,
      recoverers: [],
      selectedRecoverer: null,
      selectedDates: "",
      operations: [],
      selectedOperations: [],
    };
  },
  async mounted() {
    try {
      const res = await Promise.all([
        instance.get("/parameters/clients/"),
        instance.get("/recoveries/users/"),
      ]);
      this.clients = res[0].data.results;
      this.recoverers = res[1].data;
    } catch (err) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t("Error"),
          text: err.message,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    }
  },

  methods: {
    async saveSitutation() {
      const result = await this.$bvModal.msgBoxConfirm(
        this.$t("Are you sure you want to save this situation?"),
        {
          title: "Alert",
          size: "sm",
          okVariant: "primary",
          okTitle: this.$t("Yes"),
          cancelTitle: this.$t("No"),
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        }
      );

      if (!result) {
        return;
      }
      try {
        await instance.post("/recoveries/situation/", {
          client_id: this.selectedClient.id,
          ref_global: this.globalRef,
          recoverer_id: this.selectedRecoverer.id,
          lines: this.selectedOperations,
        });

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Success"),
            text: this.$t("The situation have been created successfully"),
            icon: "CheckIcon",
            variant: "success",
          },
        });

        this.selectedClient = null;
        this.selectedRecoverer = null;
        this.selectedDates = null;
        this.globalRef = null;
        this.selectedOperations = [];
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: err.message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    deleteOperation(item) {
      this.selectedOperations = this.selectedOperations.filter(
        (e) => e.id !== item.id
      );
    },
    async searchOperations(allowSkip) {
      try {
        const date = this.selectedDates.split("to");
        const res = await instance.get("/recoveries/client-operations/", {
          params: {
            start_date: date[0],
            end_date: date[1],
            client_id: this.selectedClient.id,
            skip: allowSkip,
          },
        });

        if (res.data.length === 0) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Alert"),
              text: this.$t("No results has been founded"),
              icon: "AlertTriangleIcon",
              variant: "warning",
            },
          });
          return;
        }

        this.selectedOperations = res.data;
      } catch (err) {
        if (err.response?.status === 400) {
          const result = await this.$bvModal.msgBoxConfirm(
            this.$t(`Reste règlement non lettre: ${err.response.data} DH`),
            {
              title: "Alert",
              size: "sm",
              okVariant: "primary",
              okTitle: this.$t("Ignore"),
              cancelTitle: this.$t("Ok"),
              cancelVariant: "outline-secondary",
              hideHeaderClose: false,
              centered: true,
            }
          );

          if (!result) {
            return;
          }

          await this.searchOperations(true);
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Error"),
              text: err.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        }
      }
    },
    async setBlSelected(bl) {
      if (!bl) {
        return;
      }

      if (this.selectedOperations.filter((e) => e.id === bl.id).length > 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Alert"),
            text: this.$t("Selected BL already been selected"),
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });
        return;
      }

      try {
        const res = await instance.post("/recoveries/client-operations/", {
          bl_ref: bl.bl_ref,
          operation_date: bl.operation_date,
        });

        this.selectedOperations.push({ ...bl, amount: res.data });

      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: err.message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    async clientSelected(value) {
      if (!value) {
        return;
      }

      try {
        const res = await instance.get("/recoveries/client-operations/", {
          params: {
            client_id: this.selectedClient.id,
          },
        });

        this.operations = res.data;
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: err.message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },

    fetchClientOptions(search, loading) {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(async () => {
        try {
          loading(true);
          const res = await instance.get("/parameters/clients/", {
            params: { search },
          });
          this.clients = res.data.results;
        } catch (err) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Error"),
              text: err.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        } finally {
          loading(false);
        }
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";
.per-page-selector {
  width: 90px;
}
</style>
